<template>
    <v-card flat>
        <v-card-text class="pa-0">
            <v-alert type="info" prominent outlined>
                <div style="max-width: 800px">
                    A space administrator will be able to modify the contents of or start applications in all current and future instances of this space, and
                    will be able to invite new people, create new instances, delete instances or the space itself.
                </div>
            </v-alert>
            <v-form class="mt-3" ref="form" v-model="valid">
                <v-textarea v-model="emailsInput" required @input="updateEmailString" rows="3" auto-grow :disabled="numInvitationsToSend > 0" outlined>
                    <template v-slot:label>Email addresses</template>
                </v-textarea>
            </v-form>
            <div v-if="emailsInput.length" class="mt-3">
                <h3 class="secondary--text">The following users will receive an invitation email to become admins of this space.</h3>
                <div>
                    <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                        <li v-if="email.status === emailValidationOutcomes.LOW_RISK_EMAIL || email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="success">
                                <v-icon small class="mr-1">check_circle</v-icon>
                                <span class="text-uppercase font-weight-bold">validated</span>
                            </v-chip>
                        </li>
                        <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="error">
                                <v-icon small class="mr-1">cancel</v-icon>
                                <span class="text-uppercase font-weight-bold">validation failed</span>
                            </v-chip>
                        </li>
                        <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="secondary">
                                <v-icon small class="mr-1">warning</v-icon>
                                <span class="text-uppercase font-weight-bold">validation error</span>
                            </v-chip>
                        </li>
                        <li v-else-if="validatingEmails">
                            {{ email.email }}
                            <v-progress-circular :size="20" class="ml-1" indeterminate color="primary" />
                        </li>
                        <li v-else>{{ email.email }}</li>
                    </ul>
                </div>
            </div>
            <InvitationErrorsDialog
                :showDialog="showInvitationErrorsDialog"
                :errorType="validationErrorType"
                :invalidEmails="invalidEmailsList"
                @proceedWithTheInvitation="inviteAdmins($event.value)"
                @validateEmailsAgain="validateEmailsAndinviteAdmins($event.value)" />
        </v-card-text>
        <v-card-actions>
            <div class="d-flex align-center justify-center flex-column w-100">
                <v-checkbox v-if="$store.state.userInfo.is_sysadmin" v-model="generateInvitationLinksOnly" class="mb-1 mt-5 pa-0">
                    <template v-slot:label>
                        <div class="d-flex align-center">
                            Generate invitations but don't send emails
                            <v-chip class="ml-1" color="info" label outlined x-small>Sysadmin</v-chip>
                        </div>
                    </template>
                </v-checkbox>
                <v-btn
                    :loading="numInvitationsToSend > 0 || validatingEmails"
                    class="mr-1"
                    @click="validateEmailsAndinviteAdmins(true)"
                    :disabled="!valid || numInvitationsToSend !== 0 || validatingEmails"
                    color="primary"
                    elevation="0">
                    <v-icon small>add</v-icon>
                    Invite
                </v-btn>
                <v-alert max-width="600" v-if="error" color="error" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Invitation Failure Error</span>
                        Failed to invite the following users
                        <ul>
                            <li v-for="(email, index) in failedInvitations" :key="index">
                                {{ email }}
                            </li>
                        </ul>
                    </div>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { splitEmails } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')

export default {
    mixins: [emailValidation],
    components: { InvitationErrorsDialog },
    data() {
        return {
            error: false,
            emailsInput: '',
            invitationType: '',
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            valid: false,
            numInvitationsToSend: 0,
            emailErrors: false,
            generateInvitationLinksOnly: false
        }
    },
    computed: {
        emails: function () {
            if (this.validatedEmails.length) {
                return this.validatedEmails
            } else if (this.emailsData.length) {
                return this.emailsData
            }
            return []
        },
        validationErrorType() {
            if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.foundInvalidEmails || this.backendError || this.emailsValidationError) {
                return true
            }
            return false
        }
    },
    methods: {
        updateEmailString: function () {
            this.validatedEmails = []
            const emails = splitEmails(this.emailsInput)
            this.emailsData = emails
            this.emailList = emails.map(email => email.email)
        },
        inviteAdmins: function (confirm) {
            if (confirm) {
                this.error = false
                const apiURL = '/invitations/invite_space_admin/' + this.$route.params.sid
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Inviting users...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                })
                this.inviting = true
                this.numInvitationsToSend = this.emailList.length
                this.emailList.forEach(email => {
                    const postBody = { requestee_email: email, generate_link_only: this.generateInvitationLinksOnly }
                    this.$axios
                        .post(apiURL, postBody, { timeout: 300000 })
                        .then(response => {})
                        .catch(() => {
                            this.failedInvitations.push(email)
                            this.error = true
                        })
                        .finally(() => {
                            this.numInvitationsToSend -= 1
                            if (this.numInvitationsToSend === 0) {
                                this.$store.dispatch('spaceStore/fetchSpaceInvitations', this.$route.params.sid)
                                this.inviting = false
                                this.generateInvitationLinksOnly = false
                                this.emailsInput = ''
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Invitations have been successfully sent. Invited users will receive an email to accept the invitation.',
                                    snackBarTimeout: 5000,
                                    snackBarIcon: 'check_circle'
                                })
                            }
                        })
                })
            }
        },
        validateEmailsAndinviteAdmins: function (confirmed) {
            if (confirmed) {
                this.validateEmails(this.emailList).finally(() => {
                    if (!this.emailsValidationError && !this.backendError && !this.foundInvalidEmails) {
                        this.inviteAdmins(true)
                    }
                })
            }
        }
    }
}
</script>
